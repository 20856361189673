<template>
  <div>
    <div class="home_top">
      <div class="left_item">
        <h3> <br> El flujo y la gestión del dinero se simplifican</h3>
        <p>Creamos soluciones que conectan a los consumidores con los productos y servicios que les encantan, al mismo tiempo que empoderamos a las empresas de todo el mundo</p>
        <div>
          <div class="downloannow" @click="goDownloan()">Empezar ahora</div>
        </div>
      </div>
      <div class="right_item">
        <img src="@/assets/images/img_building.png" alt="">
      </div>
    </div>
    <div class="bottom_desc">
      <div class="text1">Somos un empresa ubicada en Perú</div>
      <div class="text2">Legalmente constituida bajo el nombre de Servanse Consulting S.A.C. nos centramos en formas financieras para proporcionar préstamos instantáneos, facilitando la inclusión financiera para necesidades personales, cubriendo los gastos de emergencia de todo el personal y los gastos inesperados </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    goDownloan() {
      // window.location.href = 'https://aws-logs-780801304422-ap-south-1.s3.ap-south-1.amazonaws.com/RapidEfectivo1.0.1.apk'
    }
  }
}
</script>

<style lang="scss" scoped>
.home_top {
  width: 100%;
  min-width: 1400px;
  height: 739px;
  background: #fff;
  position: relative;
  padding: 50px 180px 0;
  .left_item {
    float: left;
    padding-top: 20px;
    h3 {
      font-size: 60px;
      color: #724CC6;
      font-weight: 700;
      margin-bottom: 45px;
      line-height: 75px;
      width: 560px;
    }
    p {
      font-size: 20px;
      color: #724CC6;
      margin-bottom: 50px;
      line-height: 30px;
      width: 560px
    }
  }
  .right_item {
    float: right;
    margin-top: 70px;
    img {
      width: 480px;
    }
  }
  .downloannow {
    width: 220px;
    height: 60px;
    background: #060300;
    border-radius: 5px;
    line-height: 60px;
    text-align: center;
    font-weight: 700;
    font-size: 20px;
    color: white;
  }
}
.bottom_desc {
  float: bottom;
  background: #F5F5F5;
  width: 100%;
  padding: 140px 180px;
  align-items: center;
  text-align: center;
  .text1 {
    margin: 0 auto;
    font-size: 34px;
    color: #333333;
    font-weight: 700;
    text-align: center;
  }
  .text2 {
    margin: 0 auto;
    width: 700px;
    font-size: 20px;
    line-height: 25px;
    color: #724CC6;
    text-align: center;
    margin-top: 84px;
  }
}
</style>
